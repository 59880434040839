<template>
  <div
    style="
      position: absolute;
      z-index: 1000;
      bottom: 0px;
      left: 0px;
      right: 0px;
    "
  >
    <v-alert
      dense
      tile
      border="right"
      :type="alert.type"
      dismissible
      class="my-0"
      v-for="(alert, m) in alerts"
      :key="`alert-msg-${m}`"
    >
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "Notification",
  created() {
    //How do I listen for a
    this.$eventBus.$on("alert-info", this.addItem("info"));
    this.$eventBus.$on("alert-success", this.addItem("success"));
    this.$eventBus.$on("alert-error", this.addItem("error"));
  },
  data() {
    return {
      alerts: [],
      timeout: 2000,
      error_timeout: 5000,
      limit: 5,
    };
  },
  methods: {
    addItem(type) {
      return function (msg) {
        let alert = {
          message: msg,
          type: type,
        };
        this.alerts.push(alert);
        this.limitSize();
        setTimeout(
          this.clearAlert,
          type == "error" ? this.error_timeout : this.timeout,
          alert
        );
      }.bind(this);
    },
    limitSize() {
      if (this.alerts.length >= this.limit) {
        this.alerts.shift();
      }
    },
    clearAlert(alert) {
      this.alerts = this.alerts.filter((a) => {
        return a !== alert;
      });
    },
  },
};
</script>

<style>
</style>