import store from "./store";

let api_endpoint;

if (["mydomain.com", "localhost"].includes(window.location.hostname.split(".").slice(-2).join("."))) {
    api_endpoint = `http://${window.location.hostname}:3456/api`;
} else {
    api_endpoint = "/api";
}

function objToQuery(obj) {
    return Object.keys(obj).map(i => {
        return `${i}=${obj[i] || ''}`
    }).join("&")
}

export default {
    endpoint() {
        return api_endpoint
    },
    async get(path, query, ops) {
        if (path.startsWith("/")) {
            path = path.slice(1);
        }
        if (query) {
            if (path.includes("?")) {
                throw "nee fok man jyt klaar a query"
            }
        }
        let res = await fetch(`${api_endpoint}/${path}${query ? '?' +objToQuery(query) : ''}`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            },
            credentials: 'include',
            ...ops
        });
        if (res.status == 403) {
            store.commit("loggedOut")
        }
        return res;
    },
    async post(path, data, plain, ops) {
        if (path.startsWith("/")) {
            path = path.slice(1);
        }
        let res = await fetch(`${api_endpoint}/${path}`, {
            method: "POST",
            headers: {
                "content-type": plain ? null : "application/json;charset=UTF-8"
            },
            credentials: 'include',
            body: JSON.stringify(data),
            ...ops
        })
        if (res.status == 403) {
            store.commit("loggedOut")
        }
        return res;
    },
    async postPlain(path, data, ops) {
        if (path.startsWith("/")) {
            path = path.slice(1);
        }
        let res = await fetch(`${api_endpoint}/${path}`, {
            method: "POST",
            headers: {},
            credentials: 'include',
            body: data,
            ...ops
        })
        if (res.status == 403) {
            store.commit("loggedOut")
        }
        return res;
    },
    async del(path, ops) {
        if (path.startsWith("/")) {
            path = path.slice(1);
        }
        let res = await fetch(`${api_endpoint}/${path}`, {
            method: "DELETE",
            headers: {
                "content-type": "application/json;charset=UTF-8"
            },
            credentials: 'include',
            ...ops
        })
        if (res.status == 403) {
            store.commit("loggedOut")
        }
        return res;
    },
    async patch(path, data, ops) {
        if (path.startsWith("/")) {
            path = path.slice(1);
        }
        let res = await fetch(`${api_endpoint}/${path}`, {
            method: "PATCH",
            headers: {
                "content-type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify(data),
            credentials: 'include',
            ...ops
        })
        if (res.status == 403) {
            store.commit("loggedOut")
        }
        return res;
    }
}