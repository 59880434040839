<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">Add User</v-btn>
    </template>

    <v-card>
      <v-card-title class="secondary">
        Add a user
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">fa-times</v-icon>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-text-field
          label="Name"
          v-model="user.username"
          :rules="[$rules.required, $rules.username]"
          clearable
          persistent-hint
        ></v-text-field>
        <v-text-field
          label="Email"
          v-model="user.email"
          :rules="[$rules.required, $rules.email]"
          clearable
          persistent-hint
        ></v-text-field>
        <v-text-field
          label="Password"
          v-model="user.password"
          :rules="[$rules.required, $rules.password]"
          clearable
          persistent-hint
        ></v-text-field>
        <v-switch
          label="Admin (root)"
          hint="Allows this user to access all areas"
          :true-value="1"
          :false-value="0"
          v-model="user.root"
          persistent-hint
        ></v-switch>
        <v-switch
          label="Manager"
          hint="Allows this user to add other users on the tenants they have access to"
          :true-value="1"
          :false-value="0"
          v-model="user.manager"
          v-if="user.root == 0"
          persistent-hint
        ></v-switch>
        <v-autocomplete
          class="my-8"
          v-if="user.root == 0"
          label="Give access to"
          multiple
          chips
          clearable
          clearable-chips
          v-model="user.tenants"
          :items="tenants"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!canSubmit"
          :loading="loading"
          block
          @click="submit"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddUser",
  data() {
    return {
      dialog: false,
      loading: false,
      tenants: [],
      user: {
        username: "",
        email: "",
        password: "",
        root: 0,
        manager: 0,
        tenants: [],
      },
    };
  },
  created() {
    this.loadTenants();
  },
  computed: {
    canSubmit() {
      return (
        (this.user.root == 1 ||
          (this.user.root == 0 && this.user.tenants.length > 0)) &&
        this.user.username &&
        this.$rules.email(this.user.email) == true &&
        this.$rules.password(this.user.password) == true
      );
    },
  },
  methods: {
    async loadTenants() {
      try {
        let res = await this.$api.get("tenants");
        if (res.ok) {
          this.tenants = await res.json();
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        this.$eventBus.$emit("alert-error", ex);
      }
    },
    async submit() {
      this.loading = true;
      try {
        let res = await this.$api.post("users", this.user);
        if (res.ok) {
          res = await res.json();
          this.$emit("added");
          this.$eventBus.$emit("alert-success", "Manager created");
          this.user = {
            username: "",
            email: "",
            password: "",
            root: 0,
            manager: 0,
            tenants: [],
          };
          this.dialog = false;
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>