<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center">
          <div><v-breadcrumbs exact large :items="crumbs"></v-breadcrumbs></div>
          <div class="d-flex justify-end align-center">
            <div class="d-flex align-center">
              <div v-if="selected.length">Selected ({{ selected.length }})</div>
              <span v-if="selected.length" class="mx-1"></span>
              <v-dialog persistent scrollable v-model="mail_tester_dialog" width="1024">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="warning" :disabled="!selected.length">Mail-tester.com</v-btn>
                </template>

                <v-card>
                  <v-card-title class="secondary">
                    Mail-Tester.com <v-spacer></v-spacer><v-icon @click="mail_tester_dialog = false">fa-times</v-icon>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="py-4">
                    <v-text-field label="Subject" v-model="mail.subject"></v-text-field>
                    <v-textarea label="Body" v-model="mail.body"></v-textarea>
                    <v-switch label="HTML" v-model="mail.html" :false-value="0" :true-value="1"></v-switch>

                    <div>
                      <h5>Additional headers</h5>
                      <key-value-editor v-model="mail.headers"></key-value-editor>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn @click="clearMailTesterContent" color="error" :disabled="!(mail.subject || mail.body || mail.headers.length)
                      ">Clear</v-btn>
                    <span class="mx-1"></span>
                    <v-btn text @click="setMailTesterContent" color="primary">Load content from last test</v-btn>

                    <v-spacer></v-spacer>

                    <v-btn color="warning" :loading="loading" @click="onMailerTest" :disabled="!canSubmitMailerTest">Run
                      tests
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <span class="mx-1"></span>
            </div>

            <date-time-filter @change="onDateFilters"></date-time-filter>
            <span class="mx-1"></span>
            <v-btn icon :loading="loading" @click="load"><v-icon>fa-sync</v-icon></v-btn>

          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :loading="loading" :headers="headers" group-by="tenant.name" item-key="uid" :items-per-page="100"
          v-model="selected" show-select :fixed-header="true" :items="items" :footer-props="{
            'items-per-page-options': [25, 50, 100, 250, 500, 1000],
          }">



          <template v-slot:item.tenant.name="{ item }">
            <a :href="`${protoCol}//${item.tenant.uid}.${hostName}`" target="_blank">{{ item.tenant.name }}</a>
          </template>

          <template v-slot:item.campaign.name="{ item }">
            <a :href="`${protoCol}//${item.tenant.uid}.${hostName}/app/campaigns/${item.campaign.id}`" target="_blank">{{
              item.campaign.name }}</a>
          </template>

          <template v-slot:item.username="{ item }">
            <a :href="`${protoCol}//${item.tenant.uid}.${hostName}/app/campaigns/${item.campaign.id}/accounts/${item.id}`"
              target="_blank">{{ item.username }}</a>
          </template>

          <template v-slot:item.smtp_enabled="{ item }">
            <v-icon small v-if="item.smtp_enabled">fa-check</v-icon>
            <v-icon small v-else color="secondary">fa-times</v-icon>
          </template>
          <template v-slot:item.enabled="{ item }">
            <v-icon small v-if="item.enabled">fa-check</v-icon>
            <v-icon small v-else color="secondary">fa-times</v-icon>
          </template>

          <template v-slot:item.stats.contacts="{ item }">
            <span v-if="item.stats">{{
              item.stats.contacts | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.complete="{ item }">
            <span v-if="item.stats">{{
              item.stats.complete | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.ratio="{ item }">
            <span v-if="item.stats && item.stats.ratio">{{ item.stats.ratio }}%</span>
            <span v-else>-</span>
          </template>

          <template v-slot:item.stats.incoming="{ item }">
            <span v-if="item.stats">{{
              item.stats.incoming | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.pending="{ item }">
            <span v-if="item.stats">{{
              item.stats.pending | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.sent="{ item }">
            <span v-if="item.stats">{{ item.stats.sent | formatNumber }}</span>
          </template>

          <template v-slot:item.stats.overdue="{ item }">
            <span v-if="item.stats">{{
              item.stats.overdue | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.ignored="{ item }">
            <span v-if="item.stats">{{
              item.stats.ignored | formatNumber
            }}</span>
          </template>
          <template v-slot:item.stats.filtered="{ item }">
            <span v-if="item.stats">{{
              item.stats.filtered | formatNumber
            }}</span>
          </template>

          <template v-slot:item.mail_test.createdAt="{ item }">
            <div v-if="item.mail_test" class="text-caption">
              <div v-if="item.mail_test.result">
                <div v-if="item.mail_test.result.displayedMark">
                  <a :class="classFromScore(item.mail_test.result.displayedMark)" target="_blank"
                    :href="`https://www.mail-tester.com/test-${item.mail_test.uid}`">{{
                      item.mail_test.result.displayedMark }}</a>
                </div>
                <div v-else>
                  <span class="error--text">{{ item.mail_test.result }}</span>
                </div>
              </div>
              <div v-else>
                <span v-if="item.mail_test.sentAt">Waiting for results...</span>
                <span v-else>Sending email...</span>
              </div>
              <span class="text--secondary">{{ item.mail_test.createdAt | fromNow }} ago</span>
            </div>
            <span v-else>-</span>
          </template>

          <template v-slot:footer.prepend="">
            <div class=""></div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTimeFilter from "@/components/DateTimeFilter.vue";
import KeyValueEditor from "@/components/KeyValueEditor.vue";

export default {
  components: { DateTimeFilter, KeyValueEditor },
  name: "Accounts",
  data() {
    return {
      loading: false,

      date_filters: null,
      selected: [],
      mail_tester_dialog: false,
      mail: {
        subject: "",
        body: "",
        html: 1,
        headers: [],
      },
      items: [],
      digit_column_width: 80,
      reload_tests_timeout: null,
      abortController: new AbortController(),
      isMounted: false
    };
  },
  computed: {
    canSubmitMailerTest() {
      return this.mail.subject.length && this.mail.body.length;
    },
    headers() {
      return [
        { text: "Tenant", value: "tenant.name", groupable: true },
        { text: "Campaign", value: "campaign.name" },
        { text: "Account", value: "username" },
        { text: "Smtp enabled", value: "smtp_enabled", align: "center" },
        { text: "Imap enabled", value: "enabled", align: "center" },
        { text: "Mail-tester", value: "mail_test.createdAt" },
        {
          text: "Contacts added",
          value: "stats.contacts",
          align: "right",
          width: this.digit_column_width,
        },
        {
          text: "Completed funnel",
          value: "stats.complete",
          align: "right",
          width: this.digit_column_width,
        },
        {
          text: "Ratio",
          value: "stats.ratio",
          align: "right",
          width: this.digit_column_width,
        },
        {
          text: "Incoming",
          value: "stats.incoming",
          align: "right",
          width: this.digit_column_width,
        },
        {
          text: "Outgoing pending",
          value: "stats.pending",
          align: "right",
          width: this.digit_column_width,
        },
        {
          text: "Outgoing sent",
          value: "stats.sent",
          align: "right",
          width: this.digit_column_width,
        },
        {
          text: "Outgoing overdue",
          value: "stats.overdue",
          align: "right",
          width: this.digit_column_width,
        },
        {
          text: "Filter hits",
          value: "stats.filtered",
          align: "right",
          width: this.digit_column_width,
        },
      ];
    },
    protoCol() {
      return window.location.protocol;
    },
    hostName() {
      return window.location.host;
    },
    crumbs() {
      let items = [
        { text: "Home", to: "/", exact: true },
        { text: "Mail Accounts", to: "/accounts", exact: true },
      ];
      return items;
    },
    searchParams() {
      return this.$store["getters"]["datetimefilter/get"];
    },
  },
  created() {
    this.isMounted = true;
    this.load();
  },
  beforeDestroy() {
    this.isMounted = false;
    this.abortController.abort()
  },
  methods: {
    classFromScore(scoreString) {
      let score = parseFloat(scoreString.split("/")[0]);
      if (score >= 9) {
        return `primary--text`;
      } else if (score >= 7) {
        return `warning--text`;
      } else {
        return `error--text`;
      }
    },
    clearMailTesterContent() {
      console.log("clearMailTesterContent");
      this.mail = {
        subject: "",
        body: "",
        html: 1,
        headers: [],
      };
    },
    setMailTesterContent() {
      let lastTest = this.items
        .filter((i) => {
          return i.mail_test;
        })
        .sort((a, b) => {
          if (a.mail_test.id < b.mail_test.id) {
            return 1;
          }
          if (a.mail_test.id > b.mail_test.id) {
            return -1;
          }
          return 0;
        })[0];
      console.log("lastTest", lastTest);
      if (lastTest) {
        lastTest = lastTest.mail_test;
        this.mail.subject = lastTest.subject;
        this.mail.body = lastTest.body;
        this.mail.html = lastTest.html;
        this.mail.headers = lastTest.headers;
      }
    },
    async onMailerTest() {
      this.loading = true;
      let containsDisabled = this.selected.find((i) => {
        return i.smtp_enabled == 0;
      });
      let ignoreDisabled = false;
      if (containsDisabled) {
        ignoreDisabled = confirm(
          "Some of the accounts in your selection have smtp disabled, do you want to ignore those ? (Ok=Yes, Cancel=No)"
        );
      }
      try {
        await Promise.all(
          this.selected.map(
            async function (item) {
              //For each selected account create a test.
              console.log("Test item", item);
              if (item.smtp_enabled == 0 && ignoreDisabled) {
                return;
              }
              try {
                let res = await this.$api.post(`accounts/test`, {
                  account: item,
                  subject: this.mail.subject,
                  body: this.mail.body,
                  html: this.mail.html,
                  headers: this.mail.headers,
                }, false, { signal: this.abortController.signal });
                if (!res.ok) {
                  res = await res.text();
                  throw res;
                }
              } catch (ex) {
                console.error(ex);
                this.$eventBus.$emit("alert-error", ex);
              }
            }.bind(this)
          )
        );
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.selected = [];
      this.mail_tester_dialog = false;
      this.loading = false;
      this.loadTests();
    },
    onDateFilters() {
      console.log("onDateFilters");
      this.load();
    },
    async load() {
      if (this.reload_tests_timeout) {
        clearTimeout(this.reload_tests_timeout);
      }
      this.loading = true;
      try {
        console.log("load");
        let res = await this.$api.get(`accounts`, {}, { signal: this.abortController.signal });
        if (res.ok) {
          this.items = await res.json();
          // await this.loadTests();
          // await this.loadStats();
          await Promise.all([
            this.loadTests(),
            this.loadStats()

          ])
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
    async loadStats() {
      // this.items = await Promise.all(
      //   this.items.map(
      //     async function (account) {
      //       let stats_res = await this.$api.post(`accounts/report`, {
      //         ...this.searchParams,
      //         account_id: account.id,
      //         db_name: account.tenant.database,
      //       });
      //       if (stats_res.ok) {
      //         stats_res = await stats_res.json();
      //         account.stats = stats_res;
      //         account.uid = `${account.tenant.uid}-${account.id}`;
      //       }
      //       return account;
      //     }.bind(this)
      //   )
      // );



      //Load stats 1 account at a time
      for (let i = 0; i < this.items.length; i++) {
        let account = this.items[i];
        let stats_res = await this.$api.post(`accounts/report`, {
          ...this.searchParams,
          account_id: account.id,
          db_name: account.tenant.database,
        }, false, { signal: this.abortController.signal });
        if (stats_res.ok) {
          stats_res = await stats_res.json();
          // account.stats = stats_res;
          // account.uid = `${account.tenant.uid}-${account.id}`;
          this.$set(this.items[i], 'stats', stats_res);
          this.$set(this.items[i], 'uid', `${account.tenant.uid}-${account.id}`);

        }

        //Force vue to update ui
        //this.$forceUpdate();
      }



    },
    async loadTests() {
      // this.items = await Promise.all(
      //   this.items.map(
      //     async function (account) {
      //       let res = await this.$api.get(
      //         `accounts/${account.tenant.database}/${account.id}/tests`
      //       );
      //       if (res.ok) {
      //         res = await res.json();
      //         if (res.length) {
      //           account.mail_test = res[0];
      //         }
      //       }
      //       return account;
      //     }.bind(this)
      //   )
      // );

      //Load tests 1 account at a time

      for (let i = 0; i < this.items.length; i++) {
        let account = this.items[i];
        let res = await this.$api.get(
          `accounts/${account.tenant.database}/${account.id}/tests`,
          {},
          { signal: this.abortController.signal }
        );
        if (res.ok) {
          res = await res.json();
          if (res.length) {
            account.mail_test = res[0];
          }
        }
      }

      let hasPending = this.items.find((i) => {
        //It sent but has no result yet
        //It hasnt sent yet

        return (
          i.mail_test &&
          ((!i.mail_test.sentAt && !i.mail_test.result) ||
            (i.mail_test.sentAt && !i.mail_test.result))
        );
      });
      if (hasPending) {
        console.log("hasPending", hasPending);
        this.reload_tests_timeout = setTimeout(this.loadTests.bind(this), 3000);
      }
      //See if there are pending tests and if so, schedule a reload of loadTests in 3 seconds
    },
  },
};
</script>
