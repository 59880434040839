var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-breadcrumbs',{attrs:{"exact":"","large":"","items":_vm.crumbs}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('span',{staticClass:"mx-1"}),_c('v-btn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":_vm.load}},[_c('v-icon',[_vm._v("fa-sync")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"footer-props":{
          'items-per-page-options': [25, 50, 100, 250, 500, 1000],
        }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.uid",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":(_vm.protoCol + "//" + (item.uid) + "." + _vm.hostName),"target":"_blank"}},[_vm._v(_vm._s(item.uid))])]}},{key:"item.enabled",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-switch',{staticClass:"my-0 py-0",attrs:{"dense":"","hide-details":"","true-value":1,"false-value":0},on:{"change":function($event){return _vm.onUpdateTenant(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}},{key:"item.privileges",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._l((item.privileges),function(privilege,i){return _c('v-chip',{key:("priv-" + (privilege.id) + "-chip-" + i),staticClass:"mr-1",attrs:{"close":"","small":"","label":""},on:{"click:close":function($event){return _vm.onDeletePrivilege({
                  user: privilege.user,
                  privilege: privilege,
                  tenant: item,
                })}}},[(privilege.user.manager == 1)?_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"warning"}},[_vm._v("fa-user-shield")]):_vm._e(),_vm._v(_vm._s(privilege.user.username))],1)}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-plus")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.nonRootUsers),function(user,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.onAddPrivilege({ user: user, tenant: item })}}},[_c('v-list-item-title',[_vm._v(_vm._s(user.username))])],1)}),1)],1)],2)]}},{key:"footer.prepend",fn:function(){return [_c('div',{},[_c('div',{staticClass:"text--secondary"},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"warning"}},[_vm._v("fa-user-shield")]),_vm._v("Managers ")],1)])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }