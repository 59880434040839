import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import eventBus from "./event-bus";
import api from "./api";

import validation from '../../utils/validation'
import {
  format,
  formatDistance
} from "date-fns";

Vue.prototype.$eventBus = eventBus;
Vue.prototype.$api = api;

Vue.config.productionTip = false




Vue.prototype.$fromNow = function (value) {
  if (value) {
    return formatDistance(new Date(), new Date(value))
  }
}

Vue.prototype.$formatDate = function (value) {
  if (value) {
    return format(new Date(value), 'yyyy-MM-dd HH:mm')
  }
}
Vue.prototype.$formatTime = function (value) {
  if (value) {
    return format(new Date(value), 'HH:mm:ss')
  }
}
Vue.prototype.$secondsDifference = function (a, b) {
  if (a && b) {
    return Math.abs((new Date(a) - new Date(b)) / 1000)
  }
  if (a && !b) {
    return Math.abs((new Date() - new Date(a)) / 1000)
  }
  return null
}


Vue.prototype.$rules = validation

let formatter = new Intl.NumberFormat();
Vue.prototype.$formatNumber = function (n) {
  return formatter.format(n)
}


Vue.filter('formatNumber', Vue.prototype.$formatNumber)
Vue.filter('fromNow', Vue.prototype.$fromNow)
Vue.filter('formatDate', Vue.prototype.$formatDate)
Vue.filter('secondsDifference', Vue.prototype.$secondsDifference)


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')