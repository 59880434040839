<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between">
          <div><v-breadcrumbs exact large :items="crumbs"></v-breadcrumbs></div>
          <div class="d-flex">
            <div class="d-flex align-center justify-end">
              <div class="d-flex">
                <v-select
                  outlined
                  dense
                  hide-details
                  :items="enabledOptions"
                  v-model="filters.enabled"
                  @change="onFilters"
                  item-text="text"
                  item-value="value"
                  label="Show"
                ></v-select>
              </div>
              <span class="mx-1"></span>
              <div class="d-flex">
                <v-text-field
                  outlined
                  dense
                  clearable
                  hide-details
                  :items="enabledOptions"
                  v-model="filters.search"
                  @change="onFilters"
                  label="Search"
                ></v-text-field>
              </div>
              <span class="mx-1"></span>
              <date-time-filter @change="onDateFilters"></date-time-filter>
              <v-btn class="ml-2" icon :loading="loading" @click="load"
                ><v-icon>fa-sync</v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          group-by="tenant.name"
          item-key="uid"
          :fixed-header="true"
          :items="items"
          :footer-props="{
            'items-per-page-options': [25, 50, 100, 250, 500, 1000],
          }"
        >
          <template v-slot:item.tenant.name="{ item }">
            <a
              :href="`${protoCol}//${item.tenant.uid}.${hostName}`"
              target="_blank"
              >{{ item.tenant.name }}</a
            >
          </template>

          <template v-slot:item.name="{ item }">
            <a
              :href="`${protoCol}//${item.tenant.uid}.${hostName}/app/campaigns/${item.id}`"
              target="_blank"
              >{{ item.name }}</a
            >
          </template>

          <template v-slot:item.stats.contacts="{ item }">
            <span v-if="item.stats">{{
              item.stats.contacts | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.complete="{ item }">
            <span v-if="item.stats">{{
              item.stats.complete | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.ratio="{ item }">
            <span v-if="item.stats && item.stats.ratio"
              >{{ item.stats.ratio }}%</span
            >
            <span v-else>-</span>
          </template>

          <template v-slot:item.stats.incoming="{ item }">
            <span v-if="item.stats">{{
              item.stats.incoming | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.pending="{ item }">
            <span v-if="item.stats">{{
              item.stats.pending | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.sent="{ item }">
            <span v-if="item.stats">{{ item.stats.sent | formatNumber }}</span>
          </template>

          <template v-slot:item.stats.overdue="{ item }">
            <span v-if="item.stats">{{
              item.stats.overdue | formatNumber
            }}</span>
          </template>

          <template v-slot:item.stats.ignored="{ item }">
            <span v-if="item.stats">{{
              item.stats.ignored | formatNumber
            }}</span>
          </template>
          <template v-slot:item.stats.filtered="{ item }">
            <span v-if="item.stats">{{
              item.stats.filtered | formatNumber
            }}</span>
          </template>
          <template v-slot:footer.prepend="">
            <div class=""></div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTimeFilter from "@/components/DateTimeFilter.vue";
export default {
  components: { DateTimeFilter },
  name: "Campaigns",
  data() {
    return {
      loading: false,
      date_filters: null,
      enabledOptions: [
        { text: "Enabled campaigns only", value: 1 },
        { text: "Disabled campaigns only", value: 0 },
        { text: "All campaigns", value: null },
      ],
      filters: {
        enabled: 1,
        search: null,
      },
      headers: [
        { text: "Tenant", value: "tenant.name", groupable: true },
        { text: "Campaign", value: "name" },
        { text: "Contacts added", value: "stats.contacts", align: "right" },
        { text: "Completed funnel", value: "stats.complete", align: "right" },
        { text: "Ratio", value: "stats.ratio", align: "right" },
        {
          text: "Contacts unsubscribed",
          value: "stats.unsubscribed",
          align: "right",
        },
        { text: "Incoming", value: "stats.incoming", align: "right" },
        { text: "Outgoing pending", value: "stats.pending", align: "right" },
        { text: "Outgoing sent", value: "stats.sent", align: "right" },
        { text: "Outgoing overdue", value: "stats.overdue", align: "right" },
        { text: "Filter hits", value: "stats.filtered", align: "right" },
      ],
      items: [],
    };
  },
  computed: {
    protoCol() {
      return window.location.protocol;
    },
    hostName() {
      return window.location.host;
    },
    crumbs() {
      let items = [
        { text: "Home", to: "/", exact: true },
        { text: "Campaigns", to: "/campaigns", exact: true },
      ];
      return items;
    },
    searchParams() {
      return this.$store["getters"]["datetimefilter/get"];
    },
    filterParams() {
      return {
        enabled: this.filters.enabled,
        search: this.filters.search,
      };
    },
  },
  created() {
    this.load();
  },
  methods: {
    onDateFilters() {
      console.log("onDateFilters");
      this.load();
    },
    onFilters() {
      this.load();
    },
    async load() {
      this.loading = true;
      try {
        console.log("load");
        let res = await this.$api.get(`campaigns`, this.filters);
        if (res.ok) {
          this.items = await res.json();
          this.items = await Promise.all(
            this.items.map(
              async function (campaign) {
                let stats_res = await this.$api.post(`campaigns/report`, {
                  ...this.searchParams,
                  campaign_id: campaign.id,
                  db_name: campaign.tenant.database,
                });
                if (stats_res.ok) {
                  stats_res = await stats_res.json();
                  campaign.stats = stats_res;
                  campaign.uid = `${campaign.tenant.uid}-${campaign.id}`;
                }
                return campaign;
              }.bind(this)
            )
          );
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
  },
};
</script>
