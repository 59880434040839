import Vue from 'vue'
import Vuex from 'vuex'
import api from "../api";
import datetimefilter from "./datetimefilter.store.js";
import router from "../router"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    datetimefilter: datetimefilter
  },
  state: {
    initialized: false,
    loggedIn: false,
    user: null,
    loading: false
  },
  getters: {
    isLoggedIn(state) {
      return state.loggedIn
    },
    isAdmin(state) {
      return state.user && state.user.root == 1
    },
    isRoot(state) {
      return state.user && state.user.username.toLowerCase() == 'root'
    },
    isLoading(state) {
      return state.loading
    }
  },
  mutations: {
    loggedIn(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    loggedOut(state) {
      state.loggedIn = false;
      state.user = null;
      router.push("/login")
    },
    onInit(state) {
      state.initialized = true
    }
  },
  actions: {
    async logout(context) {
      await api.get("logout");
      context.commit("loggedOut");
    },
    async login(context, creds) {
      let res = await api.post("login", creds);
      if (res.ok) {
        res = await res.json();
        context.commit('loggedIn', res)
      } else {
        res = await res.text();
        throw res;
      }
    },
    async init(context) {
      context.state.loading = true;
      let res = await api.get("init");
      if (res.ok) {
        res = await res.json();
        context.commit('loggedIn', res)
      } else {
        console.log("Login required")
      }
      context.commit("onInit")
      context.state.loading = false;
    },
  }
})