import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        dark: true,
        themes: {
            light: {
                primary: colors.lightBlue.darken2,
                font_color: colors.grey.darken4,
                secondary: "#f3f3f3",
                error: "#F44336",
                accent: colors.indigo.base, 
                heading: "#212121",
                link: "black"
            },
            dark: {
                primary: colors.lightBlue.darken2, 
                font_color: colors.grey.lighten5,
                secondary: "#363636",
                error: "#F44336",
                accent: colors.indigo.base, 
                heading: "#fff",
                link: "#fff"
            },
        },
    },

});