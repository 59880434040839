<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-card>
          <v-card-title
            >Admin Login<v-spacer></v-spacer
            ><v-icon>fa-sign-in-alt</v-icon></v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="name"
              prepend-icon="fa-user"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="password"
              prepend-icon="fa-key"
              type="password"
              label="Password"
              @keyup.enter="login"
            ></v-text-field>
          </v-card-text>
          <v-card-actions
            ><v-btn block color="primary" @click="login" :loading="loading"
              >Submit</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      password: "",
      name: "",
    };
  },
  methods: {
    async login() {
      if (!this.password) {
        return;
      }
      if (!this.name) {
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch("login", {
          password: this.password,
          name: this.name,
        });
        this.$router.push("/");
      } catch (ex) {
        this.$eventBus.$emit("alert-error", ex);
        this.password = "";
      }

      this.loading = false;
    },
  },
};
</script>

<style>
</style>