<template>
  <v-container>
    <v-row align="center">
      <v-col cols="6">
        <v-breadcrumbs exact large :items="crumbs"></v-breadcrumbs>
      </v-col>
      <v-col cols="6" class="text-right">
        <!-- <create-tenant
          @created="onTenant"
          v-if="$store.getters['isRoot']"
        ></create-tenant> -->
        <span class="mx-1"></span>
        <v-btn  icon :loading="loading" @click="load"
          ><v-icon>fa-sync</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :footer-props="{
            'items-per-page-options': [25, 50, 100, 250, 500, 1000],
          }"
        >
          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:item.uid="{ item }">
            <a :href="`${protoCol}//${item.uid}.${hostName}`" target="_blank">{{
              item.uid
            }}</a>
          </template>

          <template v-slot:item.enabled="{ item }">
            <div class="d-flex">
              <v-switch
                dense
                class="my-0 py-0"
                hide-details
                v-model="item.enabled"
                @change="onUpdateTenant(item)"
                :true-value="1"
                :false-value="0"
              ></v-switch>
            </div>
          </template>

          <template v-slot:item.privileges="{ item }">
            <div>
              <v-chip
                class="mr-1"
                close
                @click:close="
                  onDeletePrivilege({
                    user: privilege.user,
                    privilege,
                    tenant: item,
                  })
                "
                small
                label
                v-for="(privilege, i) in item.privileges"
                :key="`priv-${privilege.id}-chip-${i}`"
                ><v-icon
                  x-small
                  color="warning"
                  class="mr-1"
                  v-if="privilege.user.manager == 1"
                  >fa-user-shield</v-icon
                >{{ privilege.user.username }}</v-chip
              >

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    fab
                    dark
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>fa-plus</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(user, index) in nonRootUsers"
                    :key="index"
                    @click="onAddPrivilege({ user, tenant: item })"
                  >
                    <v-list-item-title>{{ user.username }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:footer.prepend="">
            <div class="">
              <div class="text--secondary">
                <v-icon x-small color="warning" class="mr-1"
                  >fa-user-shield</v-icon
                >Managers
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import CreateTenant from "../components/CreateTenant.vue";
export default {
  name: "Tenants",

  components: {},
  data() {
    return {
      loading: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Domain", value: "uid" },
        { text: "Enabled", value: "enabled" },
        { text: "Users with access privileges", value: "privileges" },
      ],
      items: [],
      users: [],
    };
  },
  computed: {
    nonRootUsers() {
      return this.users.filter((u) => {
        return u.root == 0;
      });
    },
    protoCol() {
      return window.location.protocol;
    },
    hostName() {
      return window.location.host;
    },
    crumbs() {
      let items = [
        { text: "Home", to: "/", exact: true },
        { text: "Tenants", to: "/tenants", exact: true },
      ];
      return items;
    },
  },
  created() {
    this.load();
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      try {
        let res = await this.$api.get("/users");
        if (res.ok) {
          this.users = await res.json();
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        this.$eventBus.$emit("alert-error", ex);
      }
    },
    onAddPrivilege({ tenant, user }) {
      //See if the user already has acess.
      if (
        user.privileges.find((p) => {
          return p.tenant.uid == tenant.uid;
        })
      ) {
        return alert(`${user.username} already has access to ${tenant.name}`);
      }

      if (
        !confirm(
          `Are you sure you want to give ${user.username} access to ${tenant.name}`
        )
      ) {
        return;
      }
      this.addPrivilege({ user, tenant });
    },
    async addPrivilege({ user, tenant }) {
      this.loading = true;
      try {
        let res = await this.$api.post(`users/${user.id}/privileges`, {
          tenant,
        });
        if (res.ok) {
          res = await res.json();
          res.user = user;
          tenant.privileges.push(res);
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
        this.load();
      }
      this.loading = false;
    },
    onDeletePrivilege({ tenant, user, privilege }) {
      if (!confirm("Are you sure you want to remove this privilege?")) {
        return;
      }
      this.deletePrivilege({ tenant, user, privilege });
    },
    async deletePrivilege({ tenant, user, privilege }) {
      this.loading = true;
      try {
        let res = await this.$api.del(
          `users/${user.id}/privileges/${privilege.id}`
        );
        if (res.ok) {
          tenant.privileges = tenant.privileges.filter((p) => {
            return p.id != privilege.id;
          });
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
        this.load();
      }
      this.loading = false;
    },
    onTenant(tenant) {
      console.log("onTenant", tenant);
      this.load();
      this.$router.push(`/tenants/${tenant.id}`);
    },
    async onUpdateTenant(tenant) {
      this.loading = true;
      try {
        console.log("load");
        let res = await this.$api.patch(`tenants/${tenant.id}`, {
          enabled: tenant.enabled,
        });
        if (res.ok) {
          this.$eventBus.$emit("alert-success", "Tenant updated");
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
    async load() {
      this.loading = true;
      try {
        console.log("load");
        let res = await this.$api.get(`tenants`);
        if (res.ok) {
          this.items = await res.json();
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
  },
};
</script>
