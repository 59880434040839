var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_c('v-breadcrumbs',{attrs:{"exact":"","large":"","items":_vm.crumbs}})],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('div',{staticClass:"d-flex"},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","items":_vm.enabledOptions,"item-text":"text","item-value":"value","label":"Show"},on:{"change":_vm.onFilters},model:{value:(_vm.filters.enabled),callback:function ($$v) {_vm.$set(_vm.filters, "enabled", $$v)},expression:"filters.enabled"}})],1),_c('span',{staticClass:"mx-1"}),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","hide-details":"","items":_vm.enabledOptions,"label":"Search"},on:{"change":_vm.onFilters},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('span',{staticClass:"mx-1"}),_c('date-time-filter',{on:{"change":_vm.onDateFilters}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","loading":_vm.loading},on:{"click":_vm.load}},[_c('v-icon',[_vm._v("fa-sync")])],1)],1)])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"group-by":"tenant.name","item-key":"uid","fixed-header":true,"items":_vm.items,"footer-props":{
          'items-per-page-options': [25, 50, 100, 250, 500, 1000],
        }},scopedSlots:_vm._u([{key:"item.tenant.name",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":(_vm.protoCol + "//" + (item.tenant.uid) + "." + _vm.hostName),"target":"_blank"}},[_vm._v(_vm._s(item.tenant.name))])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":(_vm.protoCol + "//" + (item.tenant.uid) + "." + _vm.hostName + "/app/campaigns/" + (item.id)),"target":"_blank"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.stats.contacts",fn:function(ref){
        var item = ref.item;
return [(item.stats)?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.stats.contacts)))]):_vm._e()]}},{key:"item.stats.complete",fn:function(ref){
        var item = ref.item;
return [(item.stats)?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.stats.complete)))]):_vm._e()]}},{key:"item.stats.ratio",fn:function(ref){
        var item = ref.item;
return [(item.stats && item.stats.ratio)?_c('span',[_vm._v(_vm._s(item.stats.ratio)+"%")]):_c('span',[_vm._v("-")])]}},{key:"item.stats.incoming",fn:function(ref){
        var item = ref.item;
return [(item.stats)?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.stats.incoming)))]):_vm._e()]}},{key:"item.stats.pending",fn:function(ref){
        var item = ref.item;
return [(item.stats)?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.stats.pending)))]):_vm._e()]}},{key:"item.stats.sent",fn:function(ref){
        var item = ref.item;
return [(item.stats)?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.stats.sent)))]):_vm._e()]}},{key:"item.stats.overdue",fn:function(ref){
        var item = ref.item;
return [(item.stats)?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.stats.overdue)))]):_vm._e()]}},{key:"item.stats.ignored",fn:function(ref){
        var item = ref.item;
return [(item.stats)?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.stats.ignored)))]):_vm._e()]}},{key:"item.stats.filtered",fn:function(ref){
        var item = ref.item;
return [(item.stats)?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(item.stats.filtered)))]):_vm._e()]}},{key:"footer.prepend",fn:function(){return [_c('div',{})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }