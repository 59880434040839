let startTime = null;
let startDates = null;
if (localStorage.getItem("datetimefilter-dates")) {
    startDates = JSON.parse(localStorage.getItem("datetimefilter-dates"));
} else if (localStorage.getItem("datetimefilter-time")) {
    startTime = parseInt(localStorage.getItem("datetimefilter-time"))
} else {
    startTime = 1000 * 60 * 60 * 24
}

export default {
    namespaced: true,
    state: () => ({
        time: startTime,
        dates: startDates
    }),
    mutations: {
        setDates(state, dates) {
            state.dates = dates
            if (dates) {
                localStorage.setItem("datetimefilter-dates", JSON.stringify(dates));
            } else {
                localStorage.removeItem("datetimefilter-dates")
            }
        },
        setTime(state, time) {
            state.time = time
            if (time) {
                localStorage.setItem("datetimefilter-time", time);
            } else {
                localStorage.removeItem("datetimefilter-time")
            }
        },
    },
    getters: {
        get(state) {
            return {
                time: state.time,
                dates: state.dates
            }
        }
    }
}