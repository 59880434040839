<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="4" lg="3" xl="3">
        <div class="d-flex flex-column justify-end align-center">
          <div>
            <v-subheader class="pl-0">Report dates(s)</v-subheader>
            <v-date-picker
              no-title
              v-model="selected_dates"
              color="primary"
              @input="onDates"
              :range="true"
            ></v-date-picker>
            <div class="d-flex align-center">
              <v-text-field
                v-model="selected_dates[0]"
                type="date"
              ></v-text-field>
              <span class="mx-1">to</span>
              <v-text-field
                v-model="selected_dates[1]"
                type="date"
              ></v-text-field>
            </div>
          </div>
          <v-btn block color="primary" :loading="loading" @click="load"
            >Load</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-progress-linear :indeterminate="loading"></v-progress-linear>
          <bar-chart :chart-data="chartData" />
          <!-- <v-card-actions>
              <v-icon @click="goBack">fa-chevron-left</v-icon>
              <v-spacer></v-spacer>
              <v-icon @click="goForward" v-if="page > 1">fa-chevron-right</v-icon>
              <span class="text-caption" v-else>Most recently</span>
            </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
import BarChart from "@/components/BarChart";

export default {
  name: "GlobalBlacklist",
  components: {
    BarChart,
  },
  data() {
    return {
      loading: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      selected_dates: [],

      limit: 30,
      page: 1,
      items: [],
      timestamps: [],
    };
  },
  created() {
    let today = new Date();
    let utcMonth = today.getUTCMonth() + 1;
    if (utcMonth <= 9) {
      utcMonth = `0${utcMonth}`;
    }
    let utcDay = today.getUTCDate();
    if (utcDay <= 9) {
      utcDay = `0${utcDay}`;
    }
    this.selected_dates.push(`${today.getUTCFullYear()}-${utcMonth}-${utcDay}`);
    this.selected_dates.push(`${today.getUTCFullYear()}-${utcMonth}-${utcDay}`);
    this.load();
  },
  computed: {
    filterTimes() {
      return [
        { text: "5 minutes", value: 1 * 5 },
        { text: "15 minutes", value: 1 * 15 },
        { text: "30 minutes", value: 1 * 30 },
        { text: "1 hour", value: 60 * 1 },
        { text: "2 hours", value: 60 * 2 },
        { text: "6 hours", value: 60 * 6 },
        { text: "12 hours", value: 60 * 12 },
        { text: "24 hours", value: 60 * 24 },
        { text: "48 hours", value: 60 * 48 },
        { text: "7 days", value: 60 * 24 * 7 },
      ];
    },
    chartData() {
      return {
        labels: this.timestamps,
        datasets: [
          {
            label: "Hourly Unsubscribers",
            backgroundColor: "#0288d1",
            borderColor: "#0288d1",
            borderWidth: 3,
            tension: 0.3,
            borderCapStyle: "round",
            borderJoinStyle: "miter",
            pointRadius: 0.75,
            data: this.items,
          },
        ],
      };
    },
  },
  methods: {
    goBack() {
      this.page += 1;
      this.load();
    },
    goForward() {
      this.page -= 1;
      this.load();
    },
    onChangeLimit() {
      localStorage.setItem("global_blacklist", this.limit);
      this.page = 1;
      this.load();
    },
    async load() {
      this.loading = true;
      try {
        let res = await this.$api.post(`global-blacklist/report`, {
          dates: this.selected_dates,
        });
        if (res.ok) {
          res = await res.json();

          let items = [];
          let timestamps = [];

          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            items.push(element._count);
            timestamps.push(element._label);
          }

          this.items = items;
          this.timestamps = timestamps;
          console.log("timestamps", timestamps);
        } else {
          res = await res.text();
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
  },
};
</script>
  