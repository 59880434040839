<template>
  <div class="d-flex flex-column">
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Key"
          v-model="new_item.key"
          @keypress.enter="addNewItem"
        ></v-text-field>
      </v-col>
      <v-col cols="9" class="d-flex align-center">
        <v-text-field
          label="Value"
          v-model="new_item.value"
          @keypress.enter="addNewItem"
        ></v-text-field>
        <span class="mx-1"></span>
        <v-btn
          fab
          small
          color="primary"
          @click="addNewItem"
          :disabled="!canSubmit"
        >
          <v-icon>fa-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>

    <div v-for="(item, i) in items" :key="i">
      <v-row>
        <v-col cols="3">
          <v-text-field
            label="Key"
            v-model="item.key"
            @change="onEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="9" class="d-flex align-center">
          <v-text-field
            label="Value"
            v-model="item.value"
            @change="onEdit"
          ></v-text-field>
          <span class="mx-1"></span>
          <v-btn fab small @click="removeItem(item)">
            <v-icon>fa-times</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
//Keeps an array of key/value objects
export default {
  name: "KeyValueEditor",
  props: ["value"],
  data() {
    return {
      items: [],
      new_item: {
        key: "",
        value: "",
      },
    };
  },
  created() {
    this.items = this.value;
  },
  watch: {
    value() {
      this.items = this.value;
    },
  },
  computed: {
    canSubmit() {
      return this.new_item.key.length && this.new_item.value.length;
    },
  },
  methods: {
    onEdit() {
      this.$emit("input", this.items);
      this.$emit("change");
    },
    removeItem(item) {
      if (!confirm(`Are you sure you want to remove this header ?`)) {
        return;
      }
      this.items = this.items.filter((i) => {
        return i.key !== item.key;
      });
      this.$emit("input", this.items);
      this.$emit("change");
    },
    addNewItem() {
      if (!this.canSubmit) {
        return;
      }
      if (!this.items) {
        this.items = [];
      }
      this.items.push(this.new_item);
      this.new_item = {
        key: "",
        value: "",
      };
      this.$emit("input", this.items);
      this.$emit("change");
    },
  },
};
</script>

<style>
</style>