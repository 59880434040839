<template>
  <div
    :class="stacked ? 'd-flex align-center flex-column' : 'd-flex align-center'"
  >
    <div class="d-flex" style="width: 100%">
      <v-menu
        v-model="date_picker"
        :close-on-content-click="false"
        :close-on-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date_picker_value"
            label="Stats by date range"
            outlined
            dense
            hide-details
            @click:clear="onClear"
            
            prepend-icon="fa-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          color="primary"
          dark
          range
          v-model="date_picker_value"
          no-title
        >
          <v-btn text @click="date_picker = false"> Cancel </v-btn>
     
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="onSubmitPicker" :disabled="!date_picker_value || date_picker_value.length==0"> Submit </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <div v-if="stacked" class="my-2"></div>
    <div v-else class="mx-1"></div>
    <div class="d-flex" style="width: 100%">
      <v-select
        prepend-icon="fa-clock"
        label="Stats by time"
        @change="onTime"
        outlined
        dense
        
        :value="time"
        hide-details
        :items="filterTimes"
        item-text="text"
        item-value="value"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateTimeFilter",
  props: ["value", "stacked"],
  data() {
    return {
      date_picker: false,
      date_picker_value: null,
    };
  },
  computed: {
    time() {
      return this.$store.getters["datetimefilter/get"].time;
    },
    dates() {
      return this.$store.getters["datetimefilter/get"].dates;
    },
    filterTimes() {
      return [
        { text: "Last hour", value: 1000 * 60 * 60 },
        { text: "Last 2 hours", value: 1000 * 60 * 60 * 2 },
        { text: "Last 6 hours", value: 1000 * 60 * 60 * 6 },
        { text: "Last 12 hours", value: 1000 * 60 * 60 * 12 },
        { text: "Last 24 hours", value: 1000 * 60 * 60 * 24 },
        { text: "Last 48 hours", value: 1000 * 60 * 60 * 48 },
        { text: "Last 7 days", value: 1000 * 60 * 60 * 24 * 7 },
        { text: "Last 14 days", value: 1000 * 60 * 60 * 24 * 14 },
        { text: "Last 30 days", value: 1000 * 60 * 60 * 24 * 30 },
        { text: "Last 60 days", value: 1000 * 60 * 60 * 24 * 60 },
      ];
    },
  },
  mounted() {
    if (this.dates) {
      this.date_picker_value = this.dates;
    }
  },
  watch: {},
  methods: {
    onTime(val) {
      this.$store.commit("datetimefilter/setDates", null);
      this.date_picker_value = null;
      this.$store.commit("datetimefilter/setTime", val);
      console.log("DateTimeFilter onTime", val);
      this.$emit("change");
    },
    onClear() {
      console.log("DateTimeFilter onClear");
      this.date_picker_value = null;
      this.$store.commit("datetimefilter/setDates", null);
      this.$emit("change");
    },
    onSubmitPicker() {
      this.date_picker = false;
      console.log("DateTimeFilter onSubmitPicker");
      this.$store.commit("datetimefilter/setDates", this.date_picker_value);
      this.$store.commit("datetimefilter/setTime", null);
      this.$emit("change");
    },
  },
};
</script>

<style>
</style>