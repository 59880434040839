<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="6">
        <v-breadcrumbs exact large :items="crumbs"></v-breadcrumbs>
      </v-col>
      <v-col cols="6" class="text-right">
        <div class="d-flex align-center justify-end">
          <div class="d-flex">
            <v-text-field
              label="Search"
              v-model="options.search"
              clearable
              @change="onOptions"
              hide-details
              outlined
              dense
            ></v-text-field>
          </div>

          <span class="mx-1"></span>
          <div class="d-flex">
            <v-select
              label="Log type"
              @change="onOptions"
              :items="['error', 'info']"
              v-model="options.type"
              outlined
              dense
              hide-details
            ></v-select>
          </div>
          <span class="mx-1"></span>
          <div class="d-flex">
            <v-select
              label="Since"
              suffix="hours ago"
              @change="onOptions"
              :items="[1, 2, 3, 4, 5, 6, 12, 24, 48,72]"
              v-model="options.time"
              outlined
              dense
              hide-details
            ></v-select>
          </div>
          <span class="mx-1"></span>
          <v-btn icon :loading="loading" @click="load"
            ><v-icon>fa-sync</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :fixed-header="true"
          dense
          hide-default-footer
          group-by="tenant.name"
          :items="items"
          :items-per-page="-1"
  
        >
          <template v-slot:item.campaign.name="{ item }">
            <a
              :href="`${protoCol}//${item.tenant.uid}.${hostName}/app/campaigns/${item.campaign.id}`"
              target="_blank"
              >{{ item.campaign.name }}</a
            >
          </template>

          <template v-slot:item.account.username="{ item }">
            <a
              v-if="item.account"
              :href="`${protoCol}//${item.tenant.uid}.${hostName}/app/campaigns/${item.campaign.id}/accounts/${item.account.id}`"
              target="_blank"
              >{{ item.account.username }}</a
            >
          </template>

          <template v-slot:item.log.createdAt="{ item }">
          <span :title="$formatDate(item.log.createdAt)">{{ item.log.createdAt | fromNow }} ago</span> 
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="text-center">
        <div>
          <v-btn @click="onPagePrev" :disabled="options.page <= 1" icon
            ><v-icon>fa-chevron-left</v-icon></v-btn
          >
          <span class="mx-2">{{ options.page }}</span>
          <v-btn @click="onPageNext" icon
            ><v-icon>fa-chevron-right</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
export default {
  components: {},
  name: "Logs",
  data() {
    return {
      loading: false,
      date_filters: null,
      options: {
        page: 1,
        time: 6,
        type: "error",
        limit: 32
      },
      headers: [
        { text: "Tenant", value: "tenant.name", groupable: true, width: 185 },
        { text: "Campaign", value: "campaign.name", width: 185 },
        { text: "Account", value: "account.username", width: 185 },
        { text: "Date", value: "log.createdAt", width: 185 },
        { text: "Message", value: "log.message" },
      ],
      items: [],
    };
  },
  computed: {
    protoCol() {
      return window.location.protocol;
    },
    hostName() {
      return window.location.host;
    },
    crumbs() {
      let items = [
        { text: "Home", to: "/", exact: true },
        { text: "Logs", to: "/logs", exact: true },
      ];
      return items;
    },
    searchParams() {
      return this.$store["getters"]["datetimefilter/get"];
    },
  },
  created() {
    this.load();
  },
  methods: {
    onOptions() {
      this.options.page = 1;
      this.load();
    },
    onDateFilters() {
      console.log("onDateFilters");
      this.load();
    },
    onPagePrev() {
      if (this.options.page > 1) {
        this.options.page -= 1;
        this.load();
      }
    },
    onPageNext() {
      this.options.page += 1;
      this.load();
    },
    async load() {
      this.loading = true;
      try {
        console.log("load");
        let res = await this.$api.get(`logs`, this.options);
        if (res.ok) {
          this.items = await res.json();
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
  },
};
</script>
  