<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <!-- <v-col cols="10" sm="9" md="12" lg="8" xl="4" class="text-right">
        <v-btn icon><v-icon>fa-sync</v-icon></v-btn>
      </v-col> -->
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="10" sm="9" md="6" lg="4" xl="2">
            <v-card :color="getPercentColor(system.ram)">
              <v-card-text class="text-center">
                <h6 class="text-h6">Memory usage</h6>
                <v-divider class="my-1"></v-divider>
                <h4 class="text-h4">{{ system.ram }}%</h4>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="10" sm="9" md="6" lg="4" xl="2">
            <v-card :color="getPercentColor(system.cpu)">
              <v-card-text class="text-center">
                <h6 class="text-h6">CPU usage</h6>
                <v-divider class="my-1"></v-divider>
                <h4 class="text-h4">{{ system.cpu }}%</h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="10"
        sm="9"
        md="6"
        lg="3"
        xl="2"
        v-for="(item, i) in info"
        :key="i"
      >
        <v-card color="">
          <v-card-text class="text-center">
            <h6 class="text-h6">{{ item.label }}</h6>
            <v-divider class="my-1"></v-divider>
            <h4 class="text-h4">{{ NumberFormat.format(item.value) }}</h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  components: {},
  data() {
    return {
      system: {
        ram: 22,
        cpu: 12,
      },
      info: [
        { label: "Tenants", value: 3 },
        { label: "Campaigns", value: 15 },
        { label: "Accounts", value: 55 },
        { label: "Outgoing", value: 10000 },
      ],
      NumberFormat: new Intl.NumberFormat('en-US', { })
    };
  },
  created() {
    //this.load();
  this.$router.push("/tenants")
  },
  methods: {
    getPercentColor(v) {
      if (v <= 50) {
        return "primary";
      }
      if (v <= 75) {
        return "warning";
      }
      return "error";
    },
    async load() {
      try {
        console.log("load");
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
    },
  },
};
</script>
