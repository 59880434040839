import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

import Login from "../views/Login.vue"

import Home from '../views/Home.vue'
import Users from '../views/Users.vue'
import Campaigns from '../views/Campaigns.vue'
import Accounts from '../views/Accounts.vue'
import Tenants from '../views/Tenants.vue'
import SmtpQue from '../views/SmtpQue'
import GlobalBlacklist from '../views/GlobalBlacklist'
import Logs from '../views/Logs'

import Leaderboard from "../views/Leaderboard.vue"

import eventBus from "../event-bus"

Vue.use(VueRouter)

const routes = [{
  path: '/login',
  name: 'Login',
  component: Login,
  meta: {
    requiresVisitor: true,
  }
}, {
  path: '/leaderboard',
  name: 'Leaderboard',
  component: Leaderboard,
  meta: {

  }
}, {
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    requiresAuth: true
  }
}, {
  path: '/users',
  name: 'Users',
  component: Users,
  meta: {
    requiresAuth: true,
    requiresAdmin: true
  }
}, {
  path: '/campaigns',
  name: 'Campaigns',
  component: Campaigns,
  meta: {
    requiresAuth: true,
    requiresAdmin: true
  }
}, {
  path: '/accounts',
  name: 'Accounts',
  component: Accounts,
  meta: {
    requiresAuth: true,
    requiresAdmin: true
  }
}, {
  path: '/tenants',
  name: 'Tenants',
  component: Tenants,
  meta: {
    requiresAuth: true
  }
}, {
  path: '/smtp-queue',
  name: 'SmtpQue',
  component: SmtpQue,
  meta: {
    requiresAuth: true,
    requiresAdmin: true
  }
}, {
  path: '/global-blacklist',
  name: 'GlobalBlacklist',
  component: GlobalBlacklist,
  meta: {
    requiresAuth: true,
    requiresAdmin: true
  }
}, {
  path: '/logs',
  name: 'Logs',
  component: Logs,
  meta: {
    requiresAuth: true,
    requiresAdmin: true
  }
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach(async function (to, from, next) {
  console.log("Going to", to)
  if (to.name == "Login") {
    console.log("Go login")
    return window.location.href = (["mydomain.com", "localhost"].includes(window.location.hostname.split(".").slice(-2).join("."))) ? "http://mydomain.com:3456/login" : "https://clicktracker.com/login"
  }
  if (!store.state.initialized) {
    console.log("router.beforeEach init")
    await store.dispatch('init')
  }
  next()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresVisitor)) {
    console.log("router.beforeEach requiresVisitor")
    if (store.getters['isLoggedIn']) {
      return next('/')
    }
    next()
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("router.beforeEach requiresAuth")
    if (store.getters['isLoggedIn']) {
      return next()
    }
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    console.log("router.beforeEach requiresAdmin")
    if (store.getters['isAdmin']) {
      return next()
    }
    eventBus.$emit('alert-info', 'Blocked from accessing Admin only route')
    next(from)
  } else {
    next()
  }
})

export default router