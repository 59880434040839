<template>
  <v-container>
    <v-row align="center">
      <v-col cols="6">
        <v-breadcrumbs exact large :items="crumbs"></v-breadcrumbs>
      </v-col>
      <v-col cols="6" class="text-right">
        <add-user @added="load"></add-user>
        <v-btn class="ml-2" icon :loading="loading" @click="load"><v-icon>fa-sync</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :loading="loading" :headers="headers" :items="items" :footer-props="{
          'items-per-page-options': [25, 50, 100, 250, 500, 1000],
        }">
          <template v-slot:item.root="{ item }">
            <div class="d-flex">
              <v-switch dense class="my-0 py-0" hide-details v-model="item.root" @change="onUpdateUser(item)"
                :true-value="1" :false-value="0"></v-switch>
            </div>
          </template>
          <template v-slot:item.manager="{ item }">
            <div class="d-flex">
              <v-switch v-if="item.root == 0" dense class="my-0 py-0" hide-details v-model="item.manager"
                @change="onUpdateUser(item)" :true-value="1" :false-value="0"></v-switch>
            </div>
          </template>

          <template v-slot:item.username="{ item }">
            <v-text-field dense single-line @change="onUpdateUser(item)" v-model="item.username"
              :rules="[$rules.required, $rules.username]"></v-text-field>
          </template>
          <template v-slot:item.email="{ item }">
            <v-text-field dense @change="onUpdateUser(item)" single-line v-model="item.email"
              :rules="[$rules.required, $rules.email]"></v-text-field>
          </template>

          <template v-slot:item.password="{ item }">
            <change-password @change="onChangePassword({ password: $event, user: item })"></change-password>
          </template>

          <template v-slot:item.privileges="{ item }">
            <div v-if="item.root == 0">
              <v-chip class="mr-1" close @click:close="onDeletePrivilege({ user: item, privilege })" small label
                v-for="(privilege, i) in item.privileges" :key="`priv-${privilege.id}-chip-${i}`">{{ privilege.tenant ?
                  privilege.tenant.name : privilege }}</v-chip>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" fab dark x-small v-bind="attrs" v-on="on">
                    <v-icon small>fa-plus</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item v-for="(tenant, index) in tenants" :key="index"
                    @click="onAddPrivilege({ user: item, tenant })">
                    <v-list-item-title>{{ tenant.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:footer.prepend="">
            <div class="">
              <div class="text--secondary">
                Admin users can access everything
              </div>
              <div class="text--secondary">
                Managers can add other users to tenants they have access to
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddUser from "../components/AddUser.vue";
import ChangePassword from "../components/ChangePassword.vue";

export default {
  components: { AddUser, ChangePassword },
  name: "Users",
  data() {
    return {
      loading: false,
      headers: [
        { text: "Name", value: "username" },
        { text: "Email", value: "email" },
        { text: "Password", value: "password" },
        { text: "Admin (root)", value: "root" },
        { text: "Manager", value: "manager" },
        { text: "Privileges", value: "privileges" },
      ],
      items: [],
      tenants: [],
    };
  },
  computed: {
    crumbs() {
      let items = [
        { text: "Home", to: "/", exact: true },
        { text: "Users", to: "/users", exact: true },
      ];
      return items;
    },
  },
  created() {
    this.load();
    this.loadTenants();
  },
  methods: {
    async loadTenants() {
      try {
        let res = await this.$api.get("tenants");
        if (res.ok) {
          this.tenants = await res.json();
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        this.$eventBus.$emit("alert-error", ex);
      }
    },
    onAddPrivilege({ tenant, user }) {
      //See if the user already has acess.
      if (
        user.privileges.find((p) => {
          return p.tenant.uid == tenant.uid;
        })
      ) {
        return alert(`${user.username} already has access to ${tenant.name}`);
      }

      if (
        !confirm(
          `Are you sure you want to give ${user.username} access to ${tenant.name}`
        )
      ) {
        return;
      }
      this.addPrivilege({ user, tenant });
    },
    async addPrivilege({ user, tenant }) {
      this.loading = true;
      try {
        let res = await this.$api.post(`users/${user.id}/privileges`, {
          tenant,
        });
        if (res.ok) {
          res = await res.json();
          res.tenant = tenant;
          user.privileges.push(res);
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
        this.load();
      }
      this.loading = false;
    },
    onDeletePrivilege({ user, privilege }) {
      if (!confirm("Are you sure you want to remove this privilege?")) {
        return;
      }
      this.deletePrivilege({ user, privilege });
    },
    async deletePrivilege({ user, privilege }) {
      this.loading = true;
      try {
        let res = await this.$api.del(
          `users/${user.id}/privileges/${privilege.id}`
        );
        if (res.ok) {
          user.privileges = user.privileges.filter((p) => {
            return p.id != privilege.id;
          });
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
        this.load();
      }
      this.loading = false;
    },
    async onUpdateUser(user) {
      if (this.$rules.email(user.email) !== true) {
        return;
      }
      if (this.$rules.username(user.username) !== true) {
        return;
      }

      this.loading = true;
      try {
        console.log("load");
        let res = await this.$api.patch(`users/${user.id}`, {
          username: user.username,
          email: user.email,
          root: user.root,
          manager: user.manager,
        });
        if (res.ok) {
          this.$eventBus.$emit("alert-success", "User updated");
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
        this.load();
      }
      this.loading = false;
    },
    async onChangePassword({ password, user }) {
      this.loading = true;
      try {
        console.log("load");
        let res = await this.$api.patch(`users/${user.id}`, { password });
        if (res.ok) {
          this.$eventBus.$emit("alert-success", "Password changed");
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
    async load() {
      this.loading = true;
      try {
        console.log("load");
        let res = await this.$api.get(`users`);
        if (res.ok) {
          this.items = await res.json();
        } else {
          res = await res.text();
          throw res;
        }
      } catch (ex) {
        console.error(ex);
        this.$eventBus.$emit("alert-error", ex);
      }
      this.loading = false;
    },
  },
};
</script>
