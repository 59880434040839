<template>
  <v-app>
    <v-app-bar clipped-left app v-if="$store.getters['isLoggedIn']">
      <v-icon
        v-if="['xs', 'sm', 'md'].includes($vuetify.breakpoint.name)"
        class="mr-2"
        @click="drawer = !drawer"
        >{{ drawer ? "fa fa-bars" : "fa fa-bars" }}</v-icon
      >
      <v-icon color="primary" class="mr-2">far fa-paper-plane</v-icon>
      <router-link class="text-decoration-none font_color--text text-button" to="/"
        >AUTOBOT.CHAT - Admin</router-link
      >
      <span class="mx-2"></span>
     

      <inter-conn-menu></inter-conn-menu>
      <v-spacer></v-spacer>
      <span class="text-button mr-4">
        {{ $store.state.user.username }}
      </span>
      <v-icon
        title="Sign out"
        v-if="$store.getters['isLoggedIn']"
        @click="onLogout"
        >fa-power-off</v-icon
      >
    </v-app-bar>

    <v-navigation-drawer
      clipped
      v-model="drawer"
      app
      v-if="$store.getters['isLoggedIn']"
    >
      <v-list class="pt-0">
        <v-list-item to="/tenants">
          <v-list-item-icon><v-icon>fa-server</v-icon></v-list-item-icon>
          <v-list-item-title>Tenants</v-list-item-title>
        </v-list-item>
        <v-list-item to="/users" v-if="$store.getters['isAdmin']">
          <v-list-item-icon><v-icon>fa-users</v-icon></v-list-item-icon>
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item>
        <v-list-item to="/campaigns" v-if="$store.getters['isAdmin']">
          <v-list-item-icon><v-icon>fa-mail-bulk</v-icon></v-list-item-icon>
          <v-list-item-title>Campaigns</v-list-item-title>
        </v-list-item>
        <v-list-item to="/accounts" v-if="$store.getters['isAdmin']">
          <v-list-item-icon><v-icon>fa-paper-plane</v-icon></v-list-item-icon>
          <v-list-item-title>Mail Accounts</v-list-item-title>
        </v-list-item>
         <v-list-item to="/smtp-queue" v-if="$store.getters['isAdmin']">
          <v-list-item-icon><v-icon>fa-tachometer-alt</v-icon></v-list-item-icon>
          <v-list-item-title>Smtp Queue</v-list-item-title>
        </v-list-item>
        <v-list-item to="/global-blacklist" v-if="$store.getters['isAdmin']">
          <v-list-item-icon><v-icon>fa-chart-simple</v-icon></v-list-item-icon>
          <v-list-item-title>Global Blacklist</v-list-item-title>
        </v-list-item>
        <v-list-item to="/logs" v-if="$store.getters['isAdmin']">
          <v-list-item-icon><v-icon>fa-list</v-icon></v-list-item-icon>
          <v-list-item-title>Logs</v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-overlay :value="$store.getters['isLoading']" :opacity="1">
        <v-progress-circular :size="100" indeterminate></v-progress-circular
      ></v-overlay>
      <router-view />
      <m-alerts></m-alerts>
      <dark-mode-button></dark-mode-button>
    </v-main>
  </v-app>
</template>

<script>
import Alerts from "@/components/Alerts";
import DarkModeButton from "@/components/DarkModeButton.vue";
import InterConnMenu from "@/components/InterConnMenu.vue";

export default {
  name: "App",
  components: {
    mAlerts: Alerts,
    DarkModeButton,
    InterConnMenu
},
  data: () => ({
    drawer: true,
  }),
  methods: {
    async onLogout() {
      await this.$store.dispatch("logout");
      // this.$router.push("/login");
    },
  },
};
</script>
<style >
.clicky {
  cursor: pointer;
}
</style>