module.exports = {
    required: value => !!value || 'Required.',
    number: function (value) {
        if ((new RegExp(`^[+-]?([0-9]*[${({}).locale ?  '.' : '.'}])?[0-9]+$`)).test(value)) {
            return true
        }
        return "Not a valid number"
    },
    max20: value => value.length <= 20 || 'Max 20 characters',
    url: function (value) {
        if (!value) {
            //Makes it so when no value is entered it wont show "invalid domain"
            //Thats what the "required" rule is for.
            return true
        }
        return value && value.startsWith("http") || "Invalid url. Must start with http"
    },
    campaign_name: value => {
        if (!value) {
            //Makes it so when no value is entered it wont show "invalid domain"
            //Thats what the "required" rule is for.
            return true
        }
        return value && value.length > 1 || 'Campaign name must be at least 2 characters'
    },
    username: function (value) {
        if (!value) {
            //Makes it so when no value is entered it wont show "invalid domain"
            //Thats what the "required" rule is for.
            return true
        }
        if (value.length >= 2 && value.length <= 20) {
            return true
        } else {
            return 'Username must be 2-20 characters long'
        }
    },
    password: function (value) {
        if (!value) {
            //Makes it so when no value is entered it wont show "invalid domain"
            //Thats what the "required" rule is for.
            return true
        }
        if (value.length <= 7) {
            return 'Password must be at least 8 characters and include a special character'
        }
        const symbolRegex = /^[-#!$@%^&*()_+|~=`{}[\]:";'<>?,./ ]$/;
        let hasSpecialChar = false;
        for (let char of value) {
            if (symbolRegex.test(char)) {
                hasSpecialChar = true;
            }
        }
        if (!hasSpecialChar) {
            return 'Password must be at least 8 characters and include a special character'
        }
        return true;
    },
    domain: value => {
        if (!value) {
            //Makes it so when no value is entered it wont show "invalid domain"
            //Thats what the "required" rule is for.
            return true
        }
        return value && value.split(".").length > 1 || "Not a valid domain"
    },
    email: value => {
        if (!value) {
            //Makes it so when no value is entered it wont show "invalid email"
            //Thats what the "required" rule is for.
            return true
        }
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
    },
}