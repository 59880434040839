<template>
  <v-btn
    @click="onClick"
    icon
    style="position: fixed; bottom: 10px; right: 10px"
  >
    <v-icon>fa-adjust</v-icon></v-btn
  >
</template>

<script>
export default {
  name: "DarkModeButton",
  data() {
    return {};
  },
  created() {
    if (localStorage.getItem("darkmode")) {
      if (parseInt(localStorage.getItem("darkmode")) == 1) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  methods: {
    onClick() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("darkmode", this.$vuetify.theme.dark ? 1 : 0);
    },
  },
};
</script>

<style>
</style>