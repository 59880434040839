<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <template v-slot:activator="{ on, attrs }">
      <span class="click primary--text" v-bind="attrs" v-on="on"
        >Change password</span
      >
    </template>
    <v-card>
      <v-card-title class="secondary"
        >Change password <v-spacer></v-spacer>
        <v-icon @click="dialog = false">fa-times</v-icon></v-card-title
      >
      <v-card-text class="pt-4">
        <v-text-field
          v-model="password"
          :rules="[$rules.required, $rules.password]"
          label="Enter new password"
          @keypress.enter="onSubmit"
        ></v-text-field
      ></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn block color="primary" :disabled="!canSubmit" @click="onSubmit"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChangePassword",
  created() {
    this.password = "";
  },
  watch: {
    dialog() {
      this.password = "";
    },
  },
  data() {
    return {
      password: "",
      dialog: false,
    };
  },
  computed: {
    canSubmit() {
      return this.password && this.$rules.password(this.password) === true;
    },
  },
  methods: {
    onSubmit() {
      if (!this.canSubmit) {
        return;
      }
      this.$emit("change", this.password);
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>