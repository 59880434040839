<template>
  <v-menu
    transition="slide-x-transition"
    :close-delay="666"
    open-on-hover
    offset-y
    bottom
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small v-bind="attrs" v-on="on">
        <v-icon>fa-ellipsis-vertical</v-icon>
      </v-btn>
    </template>

    <v-list class="pa-0">
      <v-list-item class="">
        <v-list-item-content
          ><span class="text-button">Go to</span></v-list-item-content
        >
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        class="white"
        target="_blank"
        href="/api/inter-conn/go-clicktracker"
      >
        <v-img
          max-width="150"
          eager
          src="https://test.react.chat/clicktracker_logo.png"
        ></v-img>
      </v-list-item>
      <v-list-item
        class="white"
        target="_blank"
        href="/api/inter-conn/go-reactchat"
      >
        <v-img
          max-width="150"
          eager
          src="https://test.clicktrackr.com/img/react-logo.png"
        ></v-img>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {};
</script>

<style>
</style>