var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-breadcrumbs',{attrs:{"exact":"","large":"","items":_vm.crumbs}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('add-user',{on:{"added":_vm.load}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","loading":_vm.loading},on:{"click":_vm.load}},[_c('v-icon',[_vm._v("fa-sync")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"footer-props":{
        'items-per-page-options': [25, 50, 100, 250, 500, 1000],
      }},scopedSlots:_vm._u([{key:"item.root",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-switch',{staticClass:"my-0 py-0",attrs:{"dense":"","hide-details":"","true-value":1,"false-value":0},on:{"change":function($event){return _vm.onUpdateUser(item)}},model:{value:(item.root),callback:function ($$v) {_vm.$set(item, "root", $$v)},expression:"item.root"}})],1)]}},{key:"item.manager",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.root == 0)?_c('v-switch',{staticClass:"my-0 py-0",attrs:{"dense":"","hide-details":"","true-value":1,"false-value":0},on:{"change":function($event){return _vm.onUpdateUser(item)}},model:{value:(item.manager),callback:function ($$v) {_vm.$set(item, "manager", $$v)},expression:"item.manager"}}):_vm._e()],1)]}},{key:"item.username",fn:function(ref){
      var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","rules":[_vm.$rules.required, _vm.$rules.username]},on:{"change":function($event){return _vm.onUpdateUser(item)}},model:{value:(item.username),callback:function ($$v) {_vm.$set(item, "username", $$v)},expression:"item.username"}})]}},{key:"item.email",fn:function(ref){
      var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","rules":[_vm.$rules.required, _vm.$rules.email]},on:{"change":function($event){return _vm.onUpdateUser(item)}},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]}},{key:"item.password",fn:function(ref){
      var item = ref.item;
return [_c('change-password',{on:{"change":function($event){return _vm.onChangePassword({ password: $event, user: item })}}})]}},{key:"item.privileges",fn:function(ref){
      var item = ref.item;
return [(item.root == 0)?_c('div',[_vm._l((item.privileges),function(privilege,i){return _c('v-chip',{key:("priv-" + (privilege.id) + "-chip-" + i),staticClass:"mr-1",attrs:{"close":"","small":"","label":""},on:{"click:close":function($event){return _vm.onDeletePrivilege({ user: item, privilege: privilege })}}},[_vm._v(_vm._s(privilege.tenant ? privilege.tenant.name : privilege))])}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-plus")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.tenants),function(tenant,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.onAddPrivilege({ user: item, tenant: tenant })}}},[_c('v-list-item-title',[_vm._v(_vm._s(tenant.name))])],1)}),1)],1)],2):_vm._e()]}},{key:"footer.prepend",fn:function(){return [_c('div',{},[_c('div',{staticClass:"text--secondary"},[_vm._v(" Admin users can access everything ")]),_c('div',{staticClass:"text--secondary"},[_vm._v(" Managers can add other users to tenants they have access to ")])])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }